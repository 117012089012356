import React from "react";

import { red } from "@mui/material/colors";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";

import Gallery from "./Gallery";
import { WhatsApp } from "@mui/icons-material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';
import CardLocal from "../cardLocal";
import Video from "./Video"

import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import CardInvitatie from "./CardInvitatie";

import { useTranslation, initReactI18next } from "react-i18next";

const Home = (props) => {

    const { t } = useTranslation();
    return (



        <ParallaxProvider>

            {data.introData.map((item) => {

                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".5" >
                        <div id="header" className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 ">
                                <h1 style={{ fontSize: '60px', textShadow: `1px 1px 2px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.mire} & {item.mireasa}</h1>
                                <h4 style={{ fontSize: '36px' }}>{t('save_data')}<br />{t('data')}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


<BlogSectionNoi/>
         
{data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="800px" opacity=".2">
                        <div className="text-center align-items-center" style={{ position: 'absolute', maxWidth: '600px', padding: '30px'}}>
                           
                                <h1 style={{ fontSize: '50px' }}>{t('suflet_title')}</h1>
                                <h4 className="mb-4">{t('suflet_messege')}</h4>
                                <h5 className="mb-4">{t('suflet_parinti')}</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12" >
                                    <h1 className="pb-3" style={{ fontSize: '30px'}}>{item.parintii_nume1}<br/>{item.parintii_nume2}</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h1 style={{ fontSize: '30px'}}>{item.parintii_nume3}<br/>{item.parintii_nume4}</h1>
                                    </div>
                </div>
                                <FavoriteIcon sx={{ color: red[500], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{t('suflet_nasii')}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.nasii_nume}</h1>
                                
                                <FavoriteIcon sx={{ color: red[500], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{t('suflet_copii')}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.copii_nume}</h1>


                        </div>
                    </Parallaximg>
                )
            })}

<Gallery/>

         <CardLocal />

         {data.blogDataConfirmare.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '30px'}}>
                                <h1 style={{ fontSize: '35px' }}>{t('confirmare_title')}</h1>
                                <h4 >{t('confirmare_messege')}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 

      
      

          
          {data.introData.map((item) => { 
        return(
          
          
           <div class="container text-center pt-5" style={{ maxWidth: '600px', padding: '30px'}}>
       

          <div >
              <p >{t('contacte')} </p>
          
      
            {item.mireasa} {item.tel} : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
           
           <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>
       {/**<a className=" m-2  btn_logo" href={item.messenger}><MessageIcon />Messenger</a><br /> */}     
          
          </div>
        </div> )
      })}



            {data.introData.map((item) => {
                return (


                    <div>
                        <h4 className="text-center mt-5" >
                        {t('cu_drag')}
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.mire} & {item.mireasa}
                        </h3>
                        
                    </div>
                  
                )
            })}





        </ParallaxProvider>



    )
}

export default Home;