import React from "react"
import 'flag-icon-css/css/flag-icons.min.css'
import i18next from "i18next"
import { useTranslation, initReactI18next } from "react-i18next";
import cookie from 'js-cookie'

const languages = [
    {
        code: 'ro',
        name: 'Româna',
        country_code: 'ro'
    },
    {
        code: 'ru',
        name: 'Russia',
        country_code: 'ru'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    }
]




const Lang =()=> {

const currentLanguageCode = cookie.get('i18next') || 'ro'
const currentLanguage = languages.find(l => l.code === currentLanguageCode)

    return (

   <>
   {languages.map(({ code, name, country_code}) => (
       <div>
      <button key={country_code}  className="lang " 
      onClick={()=> i18next.changeLanguage(code)}
      
      >{/*  disabled={code === currentLanguageCode}*/} 
          <span 
          className={`flag-icon flag-icon-${country_code} mx-2` }
          
          ></span>
        <span>{code}</span>  </button>
  {/** style={{opacity: code === currentLanguageCode ? 0.5 : 1}}*/}
   </div>
   )) }
   

   </>
    )
}

export default Lang