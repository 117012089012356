import restaurant from "./images/coliseum.jpg";
import biserica from "./images/Yardenit.jpg";
import img_card from "./images/11n.jpeg";
import imgOmSuflet from "./images/5.jpg";
import imgOmSufletmb from "./images/5m.jpg";
import imgheader from "./images/h2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";
import imgconfirmare from "./images/11.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Daniel",
        mireasa: "Nadin",
        title: "Invitatie Online Daniel & Nadin",
        data: "9 Iunie 2022",
        data_confirmare: "25 septembrie 2022",
        savedata: "~ Ne Căsătorim !!! ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "anusic007@gmail.com", 
       tel: "+972 53-435-5509",
       phone: "tel:+972534355509",
       viber: "viber://chat?number=%2B393889564008",
       whatsapp: "https://wa.me/+972534355509",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă și Sfântul Botez",
            localul: "",
            name: "Yardenit",
            ora: "14:00",
            adress: "Kvutzat Kinneret, Israel",
            harta: "https://goo.gl/maps/nXxiYscD11fCDy6h7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.148700719732!2d28.7480613!3d46.9609596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a5a5f5d7adcc261!2sTesalia!5e0!3m2!1sro!2s!4v1648740787752!5m2!1sro!2s"
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "",
            name: "Coliseum",
            ora: "20:00",
            adress: "Ha-Tsoran St 4, Netanya, Israel",
            harta: "https://goo.gl/maps/voMmXf6jvUvstjrC9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11256.287616506279!2d26.7960057!3d45.1451398!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6cc6d30a6b79fd5!2sConacul%20Trandafirilor!5e0!3m2!1sro!2s!4v1650352117838!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "VĂ INVITĂM LA CELE MAI IMPORTANTE EVENIMENTE DIN VIAȚA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele și vom îcreștena micuțul - LEONARD, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "PĂRINȚII",
            parintii_nume1: "Eremita Tatiana",
            parintii_nume2: "",
            parintii_nume3: "Sitnik Iana",
            parintii_nume4: "",
            nasii: "CU BINECUVÂNTAREA ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Popescu Nicolai & Diana",
            copii: "COPIII",
            copii_nume: "Ilinca & Nicolas",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Căsnicia este o artă ce trebuie să o creezi în fiecare zi\"",
            message: " – R. Tagore",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],
}

export default data;