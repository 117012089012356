import { green } from "@mui/material/colors";
import React, { useState } from "react";
import '../css/Gallery.css'
import CloseIcon from '@mui/icons-material/Close';


import img1 from '../images/1n.jpeg';
import img2 from '../images/2n.jpeg';
import img3 from '../images/3n.jpeg';
import img4 from '../images/5n.jpeg';
import img5 from '../images/6n.jpeg';
import img6 from '../images/7n.jpeg';
import img7 from '../images/8n.jpeg';
import img8 from '../images/9n.jpeg';
import img9 from '../images/10n.jpeg';
import img10 from '../images/13n.jpeg';


const Gallery = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
        {
            id: 2,
            imgSrc: img2,
        },
        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },
        
        {
            id: 5,
            imgSrc: img5,
        },
        {
            id: 6,
            imgSrc: img6,
        },
        {
            id: 7,
            imgSrc: img7,
        },
        
        {
            id: 8,
            imgSrc: img8,
        },
        {
            id: 9,
            imgSrc: img9,
        },
        {
            id: 10,
            imgSrc: img10,
        },
        
    ]
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }


    return (
        <>
                
            <section id="gallery" className="text-center container mt-5 mb-5">
               <h1 className="mb-2" style={{ color: green[500] }}>Gallery</h1> 
                <div className={model ? "model open" : "model"}>
                    <img src={tempimgSrc} />
                    <CloseIcon onClick={() => setModel(false)} />
                </div>
                <div className="gallery">
                    {data.map((item, index) => {
                        return (
                            <div className="pics bg-image hover-overlay ripple shadow-1-strong rounded"
                                data-ripple-color="light" key={index} onClick={() => getImg(item.imgSrc)}>
                                <img src={item.imgSrc} style={{ width: '100%' }} />
                            </div>
                        )
                    })}
                </div>
            </section>




        </>

    )
}

export default Gallery;